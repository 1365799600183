import {
  Button,
  Divider,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Field,
  Input,
  Label,
  makeStyles,
  OptionOnSelectData,
  OverlayDrawer,
} from "@fluentui/react-components";
import { Dismiss32Filled, SaveFilled } from "@fluentui/react-icons";
import useObservable from "../../../hooks/useObservable";
import {
  candidateStateService,
  globalStateService,
} from "../../../services/services";
import React, { useEffect, useState } from "react";
import { InterviewerPicker } from "./InterviewerPicker";
import { DropDownControl } from "./DropDownControl";
import { DateTimePicker } from "./DateTimePicker";
import { callFunction } from "../../../services/requestService";
import IMessage from "../../../models/IMessage";
import { AppContainer } from "../../Shared/Container/AppContainer";
import {
  getStoredDataByKey,
  setStoredDataByKey,
} from "../../../utils/cacheHelper";
import apiConfig from "../../../services/apiConfig";
import { getErrorMessage } from "../../../utils/errorHelper";
import { MetaField } from "../../../models/ApiResponse";

const useStyles = makeStyles({
  root: {
    display: "grid",
    columnGap: "20px",
    gridTemplateColumns: "repeat(2, 1fr)",
    maxWidth: "600px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  field: {
    marginTop: "10px",
  },
  footerContainer: {
    paddingLeft: "15px",
    paddingTop: "10px",
    float: "right",
    textAlign: "right",
  },
});
export const RequestInterviewPanel: React.FC = () => {
  const isPanelOpen = useObservable(
    candidateStateService.isRequestInterviewPanelOpen
  );
  const candidate = useObservable(candidateStateService.candidate);
  const userData = useObservable(globalStateService.currentUserData);
  const [picklists, setPickLists] = useState<any>();
  const [timezoneId, setTimezoneId] = useState<string>(
    userData?.data ? userData.data[0]["timeZone"]?.value : ""
  );
  const [locationId, setLocationId] = useState<string>("");
  const [meetingLink, setMeetingLink] = useState<string>("");
  const [meetingId, setMeetingId] = useState<string>("");
  const [meetingPassword, setMeetingPassword] = useState<string>("");
  const [interviewers, setInterviewers] = useState<string[]>([]);
  const [interviewMeta, setInterviewMeta] =
    useState<Record<string, MetaField>>();
  const [interviewDate, setInterviewDate] = useState<Date | undefined>(
    undefined
  );
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [interviewNum, setInterviewNum] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    message: "",
    intent: "info",
  });
  const styles = useStyles();

  useEffect(() => {
    setIsLoading(true);
  
    const fetchInterviewMeta = () => {
      const CACHE_KEY = `${candidate["bidId"]?.value}_interviewMeta`;
      const cachedData = getStoredDataByKey(CACHE_KEY);
  
      if (cachedData) {
        setInterviewMeta(cachedData);
        return Promise.resolve(); // return a resolved promise if data is cached
      } else {
        return callFunction<any>(
          "GET",
          apiConfig.candidates.interviews(candidate["bidId"]?.value),
          "",
          "",
          true
        )
          .then((response) => {
            if (response.success === true) {
              setInterviewMeta(response.meta);
              setStoredDataByKey(CACHE_KEY, response.meta);
            } else {
              setInterviewMeta(undefined);
              const message = getErrorMessage(response);
              setMessage({
                intent: "error",
                message,
              });
            }
          })
          .catch((AxiosError) => {
            setInterviewMeta(undefined);
            const message = getErrorMessage(AxiosError);
            setMessage({
              intent: "error",
              message,
            });
          });
      }
    };
  
    const fetchPickLists = () => {
      const CACHE_KEY = "picklists";
      const cachedData = getStoredDataByKey(CACHE_KEY);
  
      if (cachedData) {
        setPickLists(cachedData);
        return Promise.resolve(); // return a resolved promise if data is cached
      } else {
        return callFunction<any>("GET", apiConfig.candidates.pickLists)
          .then((response) => {
            setPickLists(response.data);
            setStoredDataByKey(CACHE_KEY, response.data);
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            setMessage({
              intent: "error",
              message,
            });
          });
      }
    };
  
    // Run both promises in parallel and stop loading only when both are done
    Promise.all([fetchInterviewMeta(), fetchPickLists()])
      .finally(() => setIsLoading(false));
  }, [candidate]);

  const onTimezoneSelect = (data: OptionOnSelectData) =>
    setTimezoneId(data.optionValue ? data.optionValue : "");

  const onLocationSelect = (data: OptionOnSelectData) =>
    setLocationId(data.optionValue ? data.optionValue : "");

  const onInterviewNumbersSelect = (data: OptionOnSelectData) =>
    setInterviewNum(data.optionValue ? data.optionValue : "");

  const disposeDialog = () => {
    setTimezoneId("");
    setInterviewDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setLocationId("");
    setMeetingId("");
    setMeetingLink("");
    setMeetingPassword("");
    setMessage({
      message: ''
    });
    candidateStateService.SetIsRequestInterviewPanelOpen(false);
  };

  const onDateSelect = (data: Date) => setInterviewDate(data);

  const onStartTimeSelect = (data: Date) => setStartTime(data);

  const onEndTimeSelect = (data: Date) => setEndTime(data);

  const saveInterview = () => {
    const requestBody = {
      bidId: `${candidate["bidId"]?.value}`,
      dateInterviewUtc: `${interviewDate?.toISOString()}`,
      startTimeUtc: `${startTime?.toISOString()}`,
      endTimeUtc: `${endTime?.toISOString()}`,
      link: `${meetingLink}`,
      password: `${meetingPassword}`,
      meetingId: `${meetingId}`,
      timeZone: `${timezoneId}`,
      interviewNum: `${interviewNum}`,
      interviewLocationType: `${locationId}`,
      additionalInterviewersId: interviewers,
    };
    setIsLoading(true);
    callFunction<any>(
      "POST",
      apiConfig.candidates.interviews(candidate["bidId"]?.value),
      "",
      requestBody
    )
      .then((response) => {
        if (response.success) {
          candidateStateService.SetAppMessage({
            message: "Interview has been created successfully.",
            intent: "success",
          });
          disposeDialog();
        }
      })
      .catch((e) => {
        const error: Error = e.error;
        candidateStateService.SetAppMessage({
          intent: "error",
          message: error.message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getLabel = (fieldName: string) => {
    const label = interviewMeta && interviewMeta[fieldName]?.displayName ? interviewMeta[fieldName]?.displayName : '';
    return label;
  };

  const isSaveButtonDisabled =
    !timezoneId ||
    !interviewDate ||
    !startTime ||
    !endTime ||
    !locationId ||
    isLoading;

  return (
    <OverlayDrawer
      size={"medium"}
      position="end"
      title="Request Interview"
      open={isPanelOpen}
    >
      <DrawerHeader>
        <Label size="large">Request Interview</Label>
        <Label size="medium">{candidate["user"]?.displayValue}</Label>
      </DrawerHeader>
      <DrawerBody>
        <AppContainer
          ignorePadding={true}
          isLoading={isLoading}
          message={message}
          setter={setMessage}
        >
          <>
            <Field label={getLabel('timeZone')} className={styles.field} required={true}>
              {picklists?.time_zones && (
                <DropDownControl
                  defaultValue={
                    userData?.data?.length
                      ? userData.data[0]["timeZone"]?.displayValue
                      : ""
                  }
                  onSelect={onTimezoneSelect}
                  options={picklists?.time_zones}
                />
              )}
            </Field>
            <DateTimePicker
              onDateSelect={onDateSelect}
              onEndTimeSelect={onEndTimeSelect}
              onStartTimeSelect={onStartTimeSelect}
              dateLabel={getLabel('dateInterviewUtc')}
              endTimeLabel={getLabel('endTimeUtc')}
              startTimeLabel={getLabel('startTimeUtc')}
            />
            <Divider style={{ marginTop: "10px" }} />
            <Field label="Location" className={styles.field} required={true}>
              {picklists?.interview_location_types && (
                <DropDownControl
                  onSelect={onLocationSelect}
                  options={picklists?.interview_location_types}
                />
              )}
            </Field>
            <Field label={getLabel('interviewNum')} className={styles.field}>
              <DropDownControl
                options={picklists?.interview_numbers}
                onSelect={onInterviewNumbersSelect}
              />
            </Field>

            <Field label={getLabel('additionalInterviewersId')} className={styles.field}>
              <InterviewerPicker
                bidId={candidate["bidId"]?.value}
                onInterviewersChange={(data) => setInterviewers(data)}
              />
            </Field>

            <Divider style={{ marginTop: "10px" }} />

            <div style={{ marginTop: "10px" }}>
              <Label size="medium">Meeting details</Label>
              <br />
              <Label size="small">
                If you've created a meeting in your calendar please enter the
                details here:
              </Label>
            </div>
            <Field label={getLabel('link')} className={styles.field}>
              <Input
                value={meetingLink}
                onChange={(e, d) => {
                  setMeetingLink(d.value);
                }}
              />
            </Field>
            <Field label={getLabel('meetingId')} className={styles.field}>
              <Input
                value={meetingId}
                onChange={(e, d) => {
                  setMeetingId(d.value);
                }}
              />
            </Field>
            <Field label={getLabel('password')} className={styles.field}>
              <Input
                value={meetingPassword}
                onChange={(e, d) => {
                  setMeetingPassword(d.value);
                }}
              />
            </Field>
          </>
        </AppContainer>
      </DrawerBody>
      <DrawerFooter>
        <div className={styles.footerContainer}>
          <Button
            icon={<SaveFilled />}
            appearance="primary"
            style={{ marginRight: "10px" }}
            onClick={saveInterview}
            disabled={isSaveButtonDisabled}
          >
            Save
          </Button>
          <Button onClick={disposeDialog} icon={<Dismiss32Filled />}>
            Cancel
          </Button>
        </div>
      </DrawerFooter>
    </OverlayDrawer>
  );
};
