import {
  OverlayDrawer,
  DrawerBody,
  Button,
  DrawerFooter,
  DrawerHeader,
  Label,
  Field,
  Checkbox,
  Rating,
  Slider,
  useId,
  makeStyles,
} from "@fluentui/react-components";
import { candidateStateService } from "../../../services/services";
import useObservable from "../../../hooks/useObservable";
import { DataItem } from "../../../models/ApiResponse";
import {
  Dismiss32Filled,
  Checkmark32Filled,
  ArrowResetFilled,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { FilterHeading } from "./FilterHeading";
import Requirement, {
  RequirementItem,
} from "../../../models/Candidates/Requirement";
import { useState } from "react";
import { groupByRequirementType } from "../../../utils/requirements";

export interface IFilterPanelProps {
  data: DataItem[];
  meta: any;
  onApplyFilters: (filteredData: DataItem[]) => void;
  onResetFilters: () => void;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  footerContainer: {
    paddingLeft: "15px",
    paddingTop: "10px",
    float: "right",
    textAlign: "right",
  },
});

export const FilterPanel: React.FC<IFilterPanelProps> = ({
  data,
  meta,
  onApplyFilters,
  onResetFilters
}) => {
  const isPanelOpen = useObservable(candidateStateService.isFilterPanelOpen);
  // State for each filter
  const [dateAvailable, setDateAvailable] = useState<Date | null | undefined>(
    null
  );
  const [bidRating, setBidRating] = useState<number>(0);
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [systemScore, setSystemScore] = useState<number>(0);
  const [selectedExperiences, setSelectedExperiences] = useState<
    RequirementItem[]
  >([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const sliderId = useId();
  const styles = useStyles();
  const disposeDialog = () => {
    candidateStateService.SetIsFilterPanelOpen(false);
  };

  const getRequirements = (): Record<string, RequirementItem[]> => {
    const requirements: Record<string, RequirementItem[]> = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i]["requirements"]?.value) {
        const groupedRequirements = groupByRequirementType(
          data[i]["requirements"]?.value
        );
        // Merge the grouped requirements into the combinedRequirements object
        for (const key in groupedRequirements) {
          if (!requirements[key]) {
            requirements[key] = [];
          }
          // Add new values and remove duplicates
          groupedRequirements[key].forEach((value) => {
            if (!requirements[key].some((e) => e.value === value.value)) {
              requirements[key].push(value);
            }
          });
        }
      }
    }
    return requirements;
  };

  const getStatuses = () => {
    const statuses = data.map((d) => d["status"]?.displayValue);
    return Array.from(new Set(statuses.filter((d) => !!d)));
  };

  // Handle name change
  const onNameChange = (name: string, checked: boolean) => {
    setSelectedNames((prev) =>
      checked ? [...prev, name] : prev.filter((n) => n !== name)
    );
  };

  // Handle experience change
  const onRequirementChange = (
    requirement: RequirementItem,
    checked: boolean
  ) => {
    setSelectedExperiences((prev) =>
      checked
        ? [...prev, requirement]
        : prev.filter((e) => e.value !== requirement.value)
    );
  };

  // Handle status change
  const onStatusChange = (status: string, checked: boolean) => {
    setSelectedStatuses((prev) =>
      checked ? [...prev, status] : prev.filter((s) => s !== status)
    );
  };
  // Apply filters on button click
  const applyFilters = () => {
    let filteredData = [...data];
    let noFilters = true;

    // Filter by dateAvailable
    if (dateAvailable) {
      noFilters=false;
      filteredData = filteredData.filter(
        (item) => new Date(item["dateAvailable"]?.value) >= dateAvailable
      );
    }

    // Filter by rating
    if (bidRating > 0) {
      noFilters=false;
      filteredData = filteredData.filter(
        (item) => Number(item["bidRating"]?.value) >= bidRating * 2
      );
    }

    // Filter by names
    if (selectedNames.length > 0) {
      noFilters=false;
      filteredData = filteredData.filter((item) =>
        selectedNames.includes(item["user"]?.displayValue)
      );
    }

    // Filter by system score

    if(systemScore>0) {
      noFilters=false;
      filteredData = filteredData.filter(item => item["systemScore"]?.value > systemScore);
    }

    // Filter by experiences
    if (selectedExperiences.length > 0) {
      noFilters=false;
      filteredData = filteredData.filter((item) => {
        const requirements: Requirement[] = item["requirements"]?.value || [];
        return selectedExperiences.some((req) =>
          requirements.some((r) => r.requirement.value === req.value)
        );
      });
    }

    // Filter by status
    if (selectedStatuses.length > 0) {
      noFilters=false;
      filteredData = filteredData.filter((item) =>
        selectedStatuses.includes(item["status"]?.displayValue)
      );
    }

    // Pass the filtered data back to parent
    onApplyFilters(filteredData);
    if(noFilters) {
      onResetFilters();
    }
    candidateStateService.SetIsFilterPanelOpen(false);
  };
  // Reset filters
  const resetFilters = () => {
    setDateAvailable(null);
    setBidRating(0);
    setSelectedNames([]);
    setSelectedExperiences([]);
    setSelectedStatuses([]);
    setSystemScore(0);
    onApplyFilters(data); // Reset to original data
    onResetFilters();
  };

  const requirements = getRequirements();

  return (
    <OverlayDrawer
      size={"medium"}
      position="end"
      title="Filters"
      open={isPanelOpen}
    >
      <DrawerHeader>
        <Label size="large">Filters</Label>
      </DrawerHeader>
      <DrawerBody>
        <FilterHeading label={meta?.dateAvailable?.displayName} />

        <Field label="Select a specific date">
          <DatePicker
            multiple={true}
            style={{ width: "47%" }}
            onSelectDate={setDateAvailable as (date?: Date | null) => void}
            value={dateAvailable}
          />
        </Field>

        <FilterHeading label={meta?.bidRating?.displayName} />
        <Rating
          defaultValue={1}
          size="large"
          value={bidRating}
          onChange={(e, data) => setBidRating(data.value)}
        />

        <FilterHeading label={meta?.user?.displayName} />
        {data.map((d, i) => (
          <Checkbox
            key={i}
            label={d["user"]?.displayValue}
            checked={selectedNames.includes(d["user"]?.displayValue)}
            onChange={(e, checkboxData) =>
              onNameChange(
                d["user"]?.displayValue,
                Boolean(checkboxData.checked)
              )
            }
          />
        ))}
        {Object.keys(requirements).map((requirementType) => (
          <>
            <FilterHeading label={requirementType} key={requirementType} />
            {requirements[requirementType].map((requirement, index) => (
              <Checkbox
                key={index}
                label={requirement.displayValue}
                checked={selectedExperiences.includes(requirement)}
                onChange={(e, checkboxData) =>
                  onRequirementChange(
                    requirement,
                    Boolean(checkboxData.checked)
                  )
                }
              />
            ))}
          </>
        ))}

        <FilterHeading label={meta?.status?.displayName} />
        {getStatuses().map((d, i) => (
          <Checkbox
            key={i}
            label={d}
            checked={selectedStatuses.includes(d)}
            onChange={(e, checkboxData) =>
              onStatusChange(d, Boolean(checkboxData.checked))
            }
          />
        ))}
        <FilterHeading label={`${meta?.systemScore?.displayName} ${systemScore>0 ? `(${systemScore})`: ''}`} />
        <div className={styles.wrapper}>
          <Label aria-hidden>0</Label>
          <Slider
            min={0}
            max={100}
            value={systemScore * 100}
            onChange={(e, d) => setSystemScore(d.value / 100)}
            id={sliderId}
            style={{ width: "100%" }}
          />
          <Label aria-hidden>100</Label>
        </div>
      </DrawerBody>
      <DrawerFooter>
        <div className={styles.footerContainer}>
          <Button
            icon={<Checkmark32Filled />}
            appearance="primary"
            style={{ marginRight: "10px" }}
            onClick={applyFilters}
          >
            Apply
          </Button>
          <Button
            onClick={resetFilters}
            icon={<ArrowResetFilled />}
            style={{ marginRight: "10px" }}
          >
            Reset
          </Button>
          <Button onClick={disposeDialog} icon={<Dismiss32Filled />}>
            Cancel
          </Button>
        </div>
      </DrawerFooter>
    </OverlayDrawer>
  );
};
