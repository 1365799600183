import * as React from "react";
import { Field, Label, makeStyles } from "@fluentui/react-components";
import { DatePicker, DatePickerProps } from "@fluentui/react-datepicker-compat";
import {
  formatDateToTimeString,
  TimePicker,
  TimePickerProps,
} from "@fluentui/react-timepicker-compat";

export interface IDateTimePickerProps {
  onDateSelect: (selectedDate: Date) => void;
  onStartTimeSelect: (startTime: Date) => void;
  onEndTimeSelect: (endTime: Date) => void;
  startTimeLabel: string;
  endTimeLabel: string;
  dateLabel: string;
}

const useStyles = makeStyles({
  root: {
    display: "grid",
    columnGap: "20px",
    gridTemplateColumns: "repeat(2, 1fr)",
    maxWidth: "600px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  field: {
    marginTop: "10px",
  },
});

export const DateTimePicker: React.FC<IDateTimePickerProps> = ({
  onDateSelect,
  onStartTimeSelect,
  onEndTimeSelect,
  endTimeLabel,
  startTimeLabel,
  dateLabel
}) => {
  const styles = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<
    Date | null | undefined
  >(null);
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);
  const [timePickerValue, setTimePickerValue] = React.useState<string>(
    selectedTime ? formatDateToTimeString(selectedTime) : ""
  );

  const [selectedEndTime, setSelectedEndTime] = React.useState<Date | null>(
    null
  );
  const [timePickerEndValue, setTimePickerEndValue] = React.useState<string>(
    selectedEndTime ? formatDateToTimeString(selectedEndTime) : ""
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const onSelectDate: DatePickerProps["onSelectDate"] = (date) => {
    setSelectedDate(date);
    onDateSelect(date || new Date());
  };

  const onTimeChange: TimePickerProps["onTimeChange"] = (_ev, data) => {
    if (data.selectedTime && selectedEndTime) {
      if (data.selectedTime > selectedEndTime) {
        setHasError(true);
        setErrorMessage("The start time cannot be later than the end time.");
      } else {
        setHasError(false);
      }
    }

    setSelectedTime(data.selectedTime);
    setTimePickerValue(data.selectedTimeText ?? "");
    onStartTimeSelect(data.selectedTime || new Date());
  };

  const onEndTimeChange: TimePickerProps["onTimeChange"] = (_ev, data) => {
    if (data.selectedTime && selectedTime) {
      if (data.selectedTime < selectedTime) {
        setHasError(true);
        setErrorMessage("The end time cannot be earlier than the start time.");
      } else {
        setHasError(false);
      }
    }

    setSelectedEndTime(data.selectedTime);
    setTimePickerEndValue(data.selectedTimeText ?? "");
    onEndTimeSelect(data.selectedTime || new Date());
  };

  const onTimePickerInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTimePickerValue(ev.target.value);
  };

  const onEndTimePickerInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTimePickerEndValue(ev.target.value);
  };

  return (
    <>
      <Field label={dateLabel} required={true} className={styles.field}>
        <DatePicker
          placeholder="Select a date..."
          value={selectedDate}
          onSelectDate={onSelectDate}
        />
      </Field>
      <div className={styles.root}>
        <Field label={startTimeLabel} required={true}>
          <TimePicker
            placeholder="Select a time..."
            freeform
            dateAnchor={selectedDate ?? undefined}
            selectedTime={selectedTime}
            onTimeChange={onTimeChange}
            value={timePickerValue}
            onInput={onTimePickerInput}
          />
        </Field>
        <Field label={endTimeLabel} required={true}>
          <TimePicker
            placeholder="Select a time..."
            freeform
            dateAnchor={selectedDate ?? undefined}
            selectedTime={selectedEndTime}
            onTimeChange={onEndTimeChange}
            value={timePickerEndValue}
            onInput={onEndTimePickerInput}
          />
        </Field>
      </div>
      {hasError && (
        <Label
          size="small"
          color="red"
          style={{ color: "red", margin: "5px 0 10px 2px" }}
        >
          {errorMessage}
        </Label>
      )}
    </>
  );
};
