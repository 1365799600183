import {
  OverlayDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  Spinner,
  Textarea,
  DrawerFooter,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  ArrowMinimizeRegular,
  ChevronLeftRegular,
  ChevronRightRegular,
  ArrowMaximizeRegular
} from "@fluentui/react-icons";
import { globalStateService } from "../../../services/services";
import useObservable from "../../../hooks/useObservable";
import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import { callFunction } from "../../../services/requestService";
import { DynamicTable } from "../../Shared/Table/DynamicTable";
import { RemoveEmptyColumns } from "../../../utils/dataFormatter";
import { Dismiss32Filled, Checkmark32Filled } from "@fluentui/react-icons";
import { ActionName } from "../../../models/IActions";
import IMessage from "../../../models/IMessage";
import ActionPayload from "../../../models/ActionPayload";
import { HeaderCards } from "../HeaderCards/HeaderCards";
import { AppMessageBar } from "../../Shared/MessageBar/MessageBar";
import apiConfig from "../../../services/apiConfig";

export interface IDetailsPanelProps {
  showActions: boolean;
  isMaximised: boolean;
}

export const DetailsPanel: React.FC<IDetailsPanelProps> = ({
  showActions,
  isMaximised,
}) => {
  const isPanelOpen = useObservable(globalStateService.isDetailsPanelOpen);
  const pendingItems = useObservable(globalStateService.pendingItems);
  const [maximised, setMaximised] = useState<boolean>(isMaximised);
  const itemId = useObservable(globalStateService.currentCostItemId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const comment = useObservable(globalStateService.currentCostItemComment);
  const [message, setMessage] = useState<IMessage>({ message: "" });
  const [details, setDetails] = useState<ApiResponse>();
  const [headers, setHeaders] = useState<ApiResponse>();
  const [actions, setActions] = useState<ApiResponse>();

  useEffect(() => {
    if (itemId) {
      const getDetails = async () => {
        try {
          setIsLoading(true);
          const [headersResponse, detailsResponse, actionsResponse] =
            await Promise.all([
              callFunction<any>("GET", apiConfig.costItems.costItem(itemId)),
              callFunction<any>("GET", apiConfig.costItems.costItemDetails(itemId)),
              callFunction<any>("GET", apiConfig.costItems.costItemActions(itemId)),
            ]);
          const formattedResponse = RemoveEmptyColumns(detailsResponse);
          setDetails(formattedResponse);
          setHeaders(headersResponse);
          setActions(actionsResponse);
        } catch (error) {
          globalStateService.SetAppMessage({
            intent: "error",
            message: "Error fetching cost item details",
          });
        } finally {
          setIsLoading(false);
        }
      };

      getDetails();
    }
  }, [itemId]);

  const disposeDialog = () => {
    globalStateService.SetPanelDetailsOpen(false);
    setIsLoading(false);
  };

  const nextPendingItem = () => {
    const currentItemIndex = pendingItems.data.findIndex(
      (d) => d["costItemId"].value === itemId
    );
    const nextIndex = currentItemIndex + 1;
    if (nextIndex !== pendingItems.data.length) {
      const nextItem = pendingItems.data[nextIndex];
      globalStateService.SetCurrentCostItemComment("");
      setIsLoading(true);
      globalStateService.SetCurrentCostItemId(
        nextItem["costItemId"].value.toString()
      );
    }
  };

  const previousPendingItem = () => {
    const currentItemIndex = pendingItems.data.findIndex(
      (d) => d["costItemId"].value === itemId
    );
    if (currentItemIndex !== 0) {
      const nextItem = pendingItems.data[currentItemIndex - 1];
      globalStateService.SetCurrentCostItemComment("");
      setIsLoading(true);
      globalStateService.SetCurrentCostItemId(
        nextItem["costItemId"].value.toString()
      );
    }
  };

  const approveRejectItem = (actionName: ActionName) => {
    if (actions) {
      const action = actions.data.find(
        (a) => a["actionName"].value === actionName
      );
      if (action && action["commentRequired"]?.value && !comment) {
        setMessage({
          intent: "error",
          message: `Please provide comments to '${actionName}' this item.`,
        });
      } else {
        if (action && action?.activityTypeId?.value) {
          setIsLoading(true);
          const actionUrl = `costitems/${itemId}/actions/${action.activityTypeId.value}`;
          const requestBody: ActionPayload = {
            comments: comment,
            activityType: Number(action.activityTypeId.value),
            costItemId: itemId,
          };

          callFunction("POST", actionUrl, "", requestBody)
            .then((r) => {
              // remove item from the pending items
              const items = pendingItems.data.filter(
                (f) => f["costItemId"].value !== itemId
              );
              const newPendingItems = { ...pendingItems, data: items };
              globalStateService.setPendingItems(newPendingItems);

              // send a success message
              globalStateService.SetAppMessage({
                intent: "success",
                message: `Cost item submitted successfully!`,
              });

              // remove the dialog
              disposeDialog();
              setIsLoading(false);
            })
            .catch((error) => {
              setMessage({
                intent: "error",
                message:
                  "Sorry, we couldn't submit the cost item. Please try again. If the problem persists, contact support.",
              });
            });
        }
      }
    }
  };

  const getActions = () => {
    if (showActions) {
      return (
        <div>
          <Button
            appearance="subtle"
            aria-label="Previous"
            icon={<ChevronLeftRegular />}
            onClick={previousPendingItem}
          />
          <Button
            appearance="subtle"
            aria-label="Next"
            icon={<ChevronRightRegular />}
            onClick={nextPendingItem}
          />
          <Button
            appearance="subtle"
            aria-label="Maximize"
            icon={maximised ? <ArrowMinimizeRegular /> : <ArrowMaximizeRegular />}
            onClick={() => setMaximised(!maximised)}
          />
          <Button
            appearance="subtle"
            aria-label="Close"
            icon={<Dismiss24Regular />}
            onClick={disposeDialog}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const costItemNum = headers?.data[0]["costItemNum"]?.value || "";

  return (
    <OverlayDrawer
      size={maximised ? "full" : "large"}
      position="end"
      open={isPanelOpen}
    >
      <DrawerHeader>
        <DrawerHeaderTitle action={getActions()}>
          {costItemNum && <span>Cost Item # {costItemNum}</span>}
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        {isLoading ? (
          <Spinner
            style={{ marginTop: "20px" }}
            size="huge"
            label="Please wait..."
          />
        ) : (
          <div style={{ paddingTop: "10px" }}>
            {message.message && (
              <div
                style={{
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <AppMessageBar
                  message={message}
                  onDismiss={() => setMessage({ message: "" })}
                />
              </div>
            )}
              { headers && <HeaderCards headers={headers} />}
              <div style={{ marginTop: "15px" }}>
                {details?.data && details?.meta && (
                  <DynamicTable
                    data={details?.data || {}}
                    meta={details?.meta || {}}
                    showDetailsColumn={false}
                    showCommentColumn={false}
                    selectionMode="single"
                  />
                )}
              </div>
          </div>
        )}
      </DrawerBody>
      <DrawerFooter>
        <div style={{ marginTop: "15px", width: maximised ? "80%" : "70%" }}>
          <Textarea
            defaultValue={comment}
            value={comment}
            disabled={isLoading}
            onChange={(ev, data) =>
              globalStateService.SetCurrentCostItemComment(data.value)
            }
            size="large"
            placeholder="type your comment here..."
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            paddingLeft: "15px",
            paddingTop: "10px",
            float: "right",
            width: maximised ? "20%" : "30%",
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => approveRejectItem(ActionName.Approve)}
            icon={<Checkmark32Filled />}
            appearance="primary"
            disabled={isLoading}
            style={{ marginRight: "10px" }}
          >
            Approve
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => approveRejectItem(ActionName.Reject)}
            icon={<Dismiss32Filled />}
          >
            Reject
          </Button>
        </div>
      </DrawerFooter>
    </OverlayDrawer>
  );
};
