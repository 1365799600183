import {
  Label,
  Card,
  makeStyles,
  Switch,
  InfoLabel,
  Divider,
  SwitchOnChangeData,
  Field,
  Rating,
  Button,
  RatingOnChangeEventData,
  RatingDisplay,
  OptionOnSelectData,
} from "@fluentui/react-components";
import { DataItem } from "../../models/ApiResponse";
import { ActionButton } from "./ActionButton";
import { useEffect, useState } from "react";
import IAction from "../../models/Shared/IAction";
import { Actions } from "../../models/IActions";
import Requirement from "../../models/Candidates/Requirement";
import { groupByRequirementType } from "../../utils/requirements";
import {
  DocumentTextRegular,
  CalendarEditRegular,
} from "@fluentui/react-icons";
import { callFunction } from "../../services/requestService";
import { candidateStateService } from "../../services/services";
import { AppDialog } from "../Shared/Dialog/AppDialog";
import DataProps from "../../models/Candidates/DataProps";
import { DropDownControl } from "./Interviews/DropDownControl";
import { getErrorMessage } from "../../utils/errorHelper";
import apiConfig from "../../services/apiConfig";
const useStyles = makeStyles({
  field: {
    margin: "10px 0",
  },
  card: {
    width: "19%",
    height: "fit-content",
  },

  labels: { gap: "6px" },

  footer: { gap: "12px" },

  scrollableDiv: {
    height: "260px",
    overflow: "auto",
  },
  interviewLink: {
    margin: "10px 0",
  },
  usernameLabel: { width: "100%", textAlign: "center" },
});

export interface ICandidateCardProps {
  candidate: DataItem;
  meta: any;
}

export const CandidateCard: React.FC<ICandidateCardProps> = ({
  candidate,
  meta,
}) => {
  const styles = useStyles();
  const [actions, setActions] = useState<Actions>();
  const [viewed, setViewed] = useState<boolean>(false);
  const [requirements, setRequirements] = useState<Requirement[]>([]);
  const [bidRating, setBidRating] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [rejectReasons, setRejectReasons] = useState<DataProps>();
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<any>();

  useEffect(() => {
    if (candidate["actions"]?.properties?.activities) {
      setActions(candidate["actions"].properties as Actions);
    }
    if (candidate["requirements"]?.value) {
      setRequirements(candidate["requirements"].value);
    }
    if (candidate["viewed"]?.value) {
      setViewed(candidate["viewed"]?.value === 1 ? true : false);
    }
    if (candidate["bidRating"]?.value) {
      const bidValue = candidate["bidRating"]?.value;
      setBidRating(bidValue && bidValue > 0 ? bidValue / 2 : 0);
    }
  }, [candidate]);

  const onActionClick = (action: any) => {
    // execute the action

    if (action.activityType === 79) {
      candidateStateService.setCandidate(candidate);
      candidateStateService.SetIsRequestInterviewPanelOpen(true);
    } else {
      const title =
        action.activityType === 69 ? "Approve for Hire" : "Reject candidate";
      const message =
        action.activityType === 69
          ? "Are you sure you want to Approve?"
          : "Are you sure you want to Reject?";

      if (action.activityType === 60) {
        setRejectReasons(action.reasons);
      }
      setDialogMessage(message);
      setDialogTitle(title);
      setSelectedAction(action);
      setDialogOpen(true);
    }
  };

  const onDialogAction = () => {
    let requestBody = {};
    if(selectedAction.activityType === 60) {
      requestBody = {
        activityType: selectedAction.activityType,
        bidId: candidate["bidId"].value,
        reason: selectedReason ?? ''
      };
    }
    else{ 
      requestBody = {
        activityType: selectedAction.activityType,
        bidId: candidate["bidId"].value
      };
    }    

    callFunction<any>("POST", `${selectedAction.actionUrl}`, "", requestBody)
      .then((response) => {
        if(response.success) {
          console.log(response);
        }        
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        candidateStateService.SetAppMessage({
          intent: "error",
          message,
        });
      }).finally(() => setDialogOpen(false));
  }

  const updateCandidate = (payload: any) => {
    // update the candidate
    callFunction<any>("POST", apiConfig.candidates.bid(candidate["bidId"].value), "", payload)
      .then((response) => {})
      .catch((error) => {
        candidateStateService.SetAppMessage({
          intent: "error",
          message: "Error updating the candidate.",
        });
      });
  };

  const onViewedChange = (data: SwitchOnChangeData) => {
    setViewed(data.checked);
    updateCandidate({ viewed: data.checked ? 1 : 0 });
  };

  const onRatingChange = (data: RatingOnChangeEventData) => {
    updateCandidate({
      bidRating: data.value * 2,
    });
    setBidRating(data.value);
  };

  const viewInterviews = () => {
    candidateStateService.setCandidate(candidate);
    candidateStateService.SetIsInterviewsPanelOpen(true);
  };

  const onDialogClose = (open: boolean) => {
    setDialogOpen(open);
  };

  const onReasonChange = (data: OptionOnSelectData) => {
    setSelectedReason(data.optionValue ?? "");
  };

  const groupedRequirements = groupByRequirementType(requirements);

  return (
    <Card className={styles.card}>
      <header>
        <div style={{ height: "6px" }}>
          <Switch
            checked={viewed}
            disabled={!actions?.activities?.length}
            onChange={(ev, data) => onViewedChange(data)}
            label={
              <InfoLabel
                info={"Use this toggle to mark the candidate as read"}
                label={viewed ? "New" : "Read"}
              />
            }
          />
        </div>
        <div style={{ float: "right" }}>
          <ActionButton
            size="small"
            label="Actions"
            actions={
              actions?.activities?.length
                ? actions.activities.map(
                    (a) =>
                      ({
                        title: a.actionName,
                        onClick: () => onActionClick(a),
                      } as IAction)
                  )
                : []
            }
            disabled={!actions?.activities?.length}
          />
        </div>
      </header>

      <div className={styles.usernameLabel}>
        <Label weight="semibold" size="large">
          {candidate["user"]?.displayValue}
        </Label>
      </div>
      <Divider />
      <div className={styles.scrollableDiv}>
        <Field
          label={meta?.status?.displayName}
          size="large"
          style={{ paddingTop: "15px" }}
        >
          <Label>{candidate["status"]?.displayValue}</Label>
        </Field>

        {candidate["dateAvailable"]?.displayValue && (
          <Field
            label={meta?.dateAvailable?.displayName}
            size="large"
            style={{ paddingTop: "15px" }}
          >
            <Label>{candidate["dateAvailable"]?.displayValue}</Label>
          </Field>
        )}

        {candidate["cvName"]?.displayValue && (
          <Field
            label={meta?.cvName?.displayName}
            size="large"
            style={{ paddingTop: "8px" }}
          >
            <Button
              appearance="transparent"
              icon={<DocumentTextRegular />}
              style={{ width: "fit-content", padding: "2" }}
            >
              {candidate["cvName"]?.displayValue}
            </Button>
          </Field>
        )}

        <Field
          label={`${meta?.payRate?.displayName} ${
            candidate["rateType"]?.displayValue
              ? `(${candidate["rateType"]?.displayValue})`
              : ""
          }`}
          size="large"
          style={{ paddingTop: "15px" }}
        >
          <Label>{candidate["payRate"]?.displayValue}</Label>
        </Field>

        {Object.keys(groupedRequirements).map((requirementType) => (
          <Field
            key={requirementType}
            label={requirementType}
            size="large"
            style={{ paddingTop: "15px" }}
          >
            {groupedRequirements[requirementType].map((requirement, index) => (
              <Label key={index}>{requirement.displayValue}</Label>
            ))}
          </Field>
        ))}

        <Field
          label={meta?.bidRating?.displayName}
          size="large"
          style={{ paddingTop: "15px" }}
        >
          {!actions?.activities?.length ? (
            <RatingDisplay value={bidRating} />
          ) : (
            <Rating
              aria-disabled={!actions?.activities?.length}
              step={0.5}
              defaultValue={bidRating}
              value={bidRating}
              size="large"
              onChange={(e, d) => onRatingChange(d)}
            />
          )}
        </Field>

        {dialogOpen && (
          <AppDialog
            open={dialogOpen}
            title={dialogTitle}
            actionTitle={"Confirm"}
            cancelButtonTitle={"Cancel"}
            actionOnClick={onDialogAction}
            onClose={onDialogClose}
            content={
              <>
                <div>{dialogMessage}</div>
                {rejectReasons && selectedAction?.activityType === 60 && (
                  <Field label={"Reason"} className={styles.field}>
                    <DropDownControl
                      defaultValue={selectedReason}
                      options={rejectReasons}
                      onSelect={onReasonChange}
                    />
                  </Field>
                )}
              </>
            }
          />
        )}

        <Divider style={{ margin: "10px 0" }} />

        <Button
          className={styles.interviewLink}
          icon={<CalendarEditRegular />}
          onClick={viewInterviews}
        >
          Interviews
        </Button>
      </div>
    </Card>
  );
};
