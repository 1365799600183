import * as axios from 'axios';
import { BearerTokenAuthProvider, createApiClient } from '@microsoft/teamsfx';
import { TeamsUserCredentialContext } from '../internal/singletonContext';

import tssSites from '../services/sites';
//import config from "../config";

type Method = axios.Method;

/**
 * Calls an Azure Function with the specified HTTP method, function name, parameters, and data.
 * @param method The HTTP method to use for the request.
 * @param functionName The name of the Azure Function to call.
 * @param params Optional query parameters to include in the request.
 * @param data Optional data to include in the request body.
 * @returns The response data from the Azure Function.
 */

async function getSite(): Promise<string> {
  return tssSites[0].url;
}

export async function callFunction<T>(method: Method, functionName: string, params?: any, data?: any, metaOnly?: boolean): Promise<T> {
  // Get the Teams user credential from the singleton context
  const credential = TeamsUserCredentialContext.getInstance().getCredential();
  if (!credential) {
    throw new Error('TeamsFx SDK is not initialized.');
  }
  let fullUrl = '';
  try {
    // Create an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const token = (await credential.getToken(''))!;
    const site = await getSite();
    fullUrl = `trying : ${site} / ${functionName}`;
    const apiClient = createApiClient(site, new BearerTokenAuthProvider(async () => token.token));

    let response: any;

    const headers = metaOnly ? { 'Content-Type': 'application/json', metaOnly: 'metaOnly' } : { 'Content-Type': 'application/json' };

    // Send the request to the Azure Function API
    response = await apiClient.request({
      method,
      url: functionName,
      params,
      data,
      headers
    });

    // Return the response data from the Azure Function
    return response.data;
  } catch (err) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = fullUrl;

      if (err?.response?.status === 404) {
        funcErrorMsg += `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy") first before running this App`;
      } else if (err.message === 'Network Error') {
        funcErrorMsg = 'Cannot call Azure Function due to network error, please check your network connection status and ';
        if (err.config?.url && err.config.url.indexOf('localhost') >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ': ' + err.response.data.error;
        }
      }
    }

    // Throw the original error if it is not an Axios error
    throw err;
  }
}

export async function callUrl<T>(method: Method, url: string, params?: any, data?: any): Promise<T> {
  // Get the Teams user credential from the singleton context
  const credential = TeamsUserCredentialContext.getInstance().getCredential();
  if (!credential) {
    throw new Error('TeamsFx SDK is not initialized.');
  }

  try {
    // Create an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const token = (await credential.getToken(''))!;
    const apiClient = createApiClient(url, new BearerTokenAuthProvider(async () => token.token));

    let response: any;
    // Send the request to the Azure Function API
    response = await apiClient.request({
      method,
      params,
      data,
      headers: { 'Content-Type': 'application/json' }
    });

    // Return the response data from the Azure Function
    return response.data;
  } catch (err) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = '';

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy") first before running this App`;
      } else if (err.message === 'Network Error') {
        funcErrorMsg = 'Cannot call Azure Function due to network error, please check your network connection status and ';
        if (err.config?.url && err.config.url.indexOf('localhost') >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ': ' + err.response.data.error;
        }
      }
    }

    // Throw the original error if it is not an Axios error
    throw err;
  }
}
