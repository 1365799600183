import {
  Button,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Label,
  makeStyles,
  OverlayDrawer,
} from "@fluentui/react-components";
import { Dismiss32Filled } from "@fluentui/react-icons";
import useObservable from "../../../hooks/useObservable";
import { candidateStateService } from "../../../services/services";
import React, { useEffect, useState } from "react";
import { callFunction } from "../../../services/requestService";
import IMessage from "../../../models/IMessage";
import { AppContainer } from "../../Shared/Container/AppContainer";
import {
  getStoredDataByKey,
  setStoredDataByKey,
} from "../../../utils/cacheHelper";
import { ApiResponse, MetaField } from "../../../models/ApiResponse";
import { DynamicTable } from "../../Shared/Table/DynamicTable";
import { isEmptyObject } from "../../../utils/utils";
import apiConfig from "../../../services/apiConfig";
import { getErrorMessage } from "../../../utils/errorHelper";

const useStyles = makeStyles({
  root: {
    display: "grid",
    columnGap: "20px",
    gridTemplateColumns: "repeat(2, 1fr)",
    maxWidth: "600px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  field: {
    marginTop: "10px",
  },
  footerContainer: {
    paddingLeft: "15px",
    paddingTop: "10px",
    float: "right",
    textAlign: "right",
  },
});
export const InterviewsPanel: React.FC = () => {
  const isPanelOpen = useObservable(
    candidateStateService.isInterviewsPanelOpen
  );
  const candidate = useObservable(candidateStateService.candidate);
  const [interviews, setInterviews] = useState<ApiResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    message: "",
    intent: "info",
  });
  const styles = useStyles();

  const getFilteredMeta = (metaFields: Record<string, MetaField>) => {
    let filteredFields: Record<string, MetaField> = {};
    if (!isEmptyObject(metaFields)) {
      const interviewMetaFields = [
        "interviewer",
        "status",
        "dateInterviewUtc",
        "startTimeUtc",
        "endTimeUtc",
        "timeZone",
        "interviewNum",
      ];
      filteredFields = Object.fromEntries(
        Object.entries(metaFields).filter(([key, value]) =>
          interviewMetaFields.includes(value.fieldName)
        )
      );
    }
    return filteredFields;
  };

  const getInterviews = () => {
    setIsLoading(true);
    const CACHE_KEY = `${candidate["bidId"]?.value}__interviews`;
    const cachedData = getStoredDataByKey(CACHE_KEY);
    if (cachedData) {
      setInterviews(cachedData);
      setIsLoading(false);
    } else {
      callFunction<any>(
        "GET",
        apiConfig.candidates.interviews(candidate["bidId"]?.value)
      )
        .then((response) => {
          if (response.success === true) {
            setInterviews(response);
            setStoredDataByKey(CACHE_KEY, response);
          } else {
            setInterviews(undefined);
            const message = getErrorMessage(response);
            setMessage({
              intent: 'error',
              message
            });
          }
        })
        .catch((AxiosError) => {
          setInterviews(undefined);
          const message = getErrorMessage(AxiosError);
            setMessage({
              intent: 'error',
              message
            });
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (candidate["bidId"]?.value) {
      getInterviews();
    }
  }, [candidate]);

  const disposeDialog = () => {
    //setInterviews(undefined);
    setMessage({ message: "" });
    candidateStateService.SetIsInterviewsPanelOpen(false);
  };
  return (
    <OverlayDrawer
      size={"full"}
      position="end"
      title="Interviews"
      open={isPanelOpen}
    >
      <DrawerHeader>
        <Label size="large">
          Interviews ({candidate["user"]?.displayValue})
        </Label>
      </DrawerHeader>
      <DrawerBody>
        <AppContainer
          ignorePadding={true}
          isLoading={isLoading}
          message={message}
          setter={setMessage}
        >
          <div style={{ marginTop: "15px" }}>
            {!isEmptyObject(interviews?.meta || {}) && (
              <DynamicTable
                data={interviews?.data || []}
                meta={interviews?.meta || {}}
                showDetailsColumn={false}
                showCommentColumn={false}
                selectionMode="single"
              />
            )}
          </div>
        </AppContainer>
      </DrawerBody>
      <DrawerFooter>
        <div className={styles.footerContainer}>
          <Button onClick={disposeDialog} icon={<Dismiss32Filled />}>
            Close
          </Button>
        </div>
      </DrawerFooter>
    </OverlayDrawer>
  );
};
